<template>
  <!-- <div class="text-center" style="background-color:red"> -->
    <!-- <div> -->
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    style="margin:auto"
  >
    <v-container>
      <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
        Datos de usuario
      </div>

    <v-row justify="center">
      <v-col cols="auto">
        <hr style="margin-left:10px; margin-right:10px">
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto">
        <h2 style="text-align: center">{{nombreUsuario}}</h2>
      </v-col>
    </v-row>
    <!-- <h4>{{tituloSecundario}}</h4> -->

    <v-row justify="center">
      <v-col cols="12" sm="9" md="7" lg="5" xl="4">
        <v-overflow-btn
          dense
          v-model="empresa"
          :items="per.PerEmp"
          item-value="EmpId"
          item-text="Emp.EmpNom"
          label="Empresa"
          outlined
          :return-object="true"
          ref="empresaCtrl"
          class="mt-3 mb-n6"
          hide-no-data
          :disabled = "$store.state.esTienda"
        ></v-overflow-btn>      
      </v-col>
    </v-row>

    <!-- <v-row justify="center">
      <v-col>
        <hr style="margin-left:10px; margin-right:10px">
      </v-col>
    </v-row>

    <v-row justify="center" class="mb-n7" v-show="$store.state.esTienda || $store.state.esDepartamento">
      <v-col cols="9" sm="7" md="5" lg="4" xl="3">
        <v-text-field
          dense
          outlined
          v-model="passwordActual"
          :rules="passwordActualRules"
          label="Contraseña actual"
          required
          prepend-icon="mdi-lock"
          :disabled = "passwordActualValidada"
          :append-icon="showPasswordActual ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPasswordActual ? 'text' : 'password'"
          @click:append="showPasswordActual = !showPasswordActual"
        ></v-text-field>        
      </v-col>
    </v-row> -->

    <!-- <v-row justify="center" v-show="$store.state.esTienda || $store.state.esDepartamento">
      <v-col cols="5" sm="4" md="4" lg="3" xl="2">
        <v-btn
          block
          color="warning"
          @click="comprobarPasswordActual"
          :disabled = "passwordActualValidada"
        >
          Comprobar
        </v-btn>        
      </v-col>
    </v-row>

    <v-row class="mt-3" justify="center">
      <v-col cols="8" sm="6" md="4" lg="3" xl="2">
        <v-btn
          block
          color="error"
          @click="CerrarSesion"
          v-show="$store.state.usuario != '' && $store.state.usuario != null"
          :disabled = "!passwordActualValidada && ($store.state.esTienda || $store.state.esDepartamento)"
          >
            Cerrar sesión
        </v-btn>
      </v-col>

      <v-col cols="8" sm="6" md="4" lg="3" xl="2">
        <v-btn
          block
          color="error"
          @click="CerrarSesionAuxiliar"
          v-show="$store.state.usuarioAux != '' && $store.state.usuarioAux != null"
          >
            Cerrar sesión auxiliar
        </v-btn>
      </v-col>

    </v-row>

    <v-row justify="center">
      <v-col cols="auto">
        <hr style="margin-left:10px; margin-right:10px">
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="9" md="7" lg="5" xl="4">
          <UsrFind :label="perCatAuxTitulo" :perCatIdAnyLevel="perCatIdAuxFiltro" class="mb-n7" v-model="usrAux"></UsrFind>
      </v-col>
    </v-row>

    <v-row justify="center" class="mb-n7" v-show="$store.state.esTienda || $store.state.esDepartamento">
      <v-col cols="9" sm="7" md="5" lg="4" xl="3">
        <v-text-field
          dense
          outlined
          v-model="passwordAux"
          :rules="passwordAuxRules"
          label="Contraseña"
          required
          prepend-icon="mdi-lock"
          :append-icon="showPasswordAux ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPasswordAux ? 'text' : 'password'"
          @click:append="showPasswordAux = !showPasswordAux"
        ></v-text-field>        
      </v-col>
    </v-row> -->

    <v-row justify="center">
      <v-col cols="5" sm="4" md="4" lg="3" xl="2">
        <v-btn
          block
          @click="enviar"
        >
          Aceptar
        </v-btn>        
      </v-col>
    </v-row>



      <!-- <br> -->

    <!-- </div> -->
    </v-container>
  </v-form>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
  // import axios from "axios";
  import { mapState } from "vuex";
  // import UsrFind from "./UsrFind.vue";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz', 'per', 'emp', 'nombreUsuario'])
    },
    components: {
    },

    data: () => ({
      valid: true,
      empresa: {},
      tituloPrincipal: "",
      tituloSecundario: "",
      passwordActualValidada: false,
      perCatId1AuxFiltro: 0,
      perCatAuxTitulo: "",
      usrAux: [],
      passwordAux: ""
    }),

    methods: {
      cambioEmpresa(){

        this.$store.commit('empIdSet', this.empresa.EmpId);
        this.$store.commit('empSet', this.empresa.Emp);
        this.$store.commit('lookSet', {nombreLook: "", empId: this.empId});
      },
      enviar () {
        this.cambioEmpresa();
        this.$router.push("/");

        // var loginObj;
        // if (this.$store.state.esTienda) {
        //   loginObj = { UserName: this.usrAux.UsrLogin, Password: this.passwordAux };
        // }
        // else {
        //   loginObj = { UserName: this.usrAux.UsrLogin, Password: this.usrAux.UsrPwd };
        // }
        // var loginJson = JSON.stringify(loginObj);

        //  axios({ method: "POST", "url": this.urlRaiz + "/api/login/authenticate", "data": loginJson, "headers": { "content-type": "application/json" } })
        //     .then(result => {
        //           var authTokenAux = result.data;
        //           localStorage.setItem('tokenAux', authTokenAux);
        //           this.$store.dispatch('AsignarUsuarioAux').then(() => {
        //             this.$store.dispatch('GetMensajes');

        //             // Consultar mensajes del usuario lector. Inicio
        //             if (this.$store.state.esTienda|| this.$store.state.esDepartamento) {
        //               var controllerParameters = {
        //                         Action: "GET_MENSAJES_RECIBIDOS",
        //                         FiltroLectura: 'Sin leer',
        //                         FiltroDesdeFecha: new Date(""),
        //                         FiltroHastaFecha: new Date(""),
        //                         FiltroPerIdOri: null,
        //                         FiltroMsjCatId: null,
        //                         FiltroAsunto: "",
        //                         NumRegsPag: 100,
        //                         NumPag: 1,
        //                         EsEmpleado: true,
        //                         PerId: this.$store.state.perIdAux,
        //                         PerIdAux: null
        //                     } ;       

        //               var AuthToken = localStorage.getItem('token');
        //               axios({ method: "POST", "url": this.urlRaiz + "/api/msj", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
        //                     const totalMensajes = result.data.EntsTotCount;

        //                     if (totalMensajes > 0) {
        //                       var textoMensajes = "mensajes";
        //                       var textoPersonales = "individuales";
        //                       if (totalMensajes == 1) {
        //                         textoMensajes = "mensaje";
        //                         textoPersonales = "individual";
        //                       }
        //                       alert("Tiene " + totalMensajes.toString() + " " + textoMensajes + " " + textoPersonales + " sin leer");
        //                     }
        //               });                      
        //             }


        //             // Consultar mensajes del usuario lector. Final

        //           })
        //     })
        //     .catch(error => {
        //       if (error.response.status == 401){
        //         alert("Usuario o contraseña incorrecto");
        //       }
        //       else{
        //         alert(error);
        //       }
        //     })
      },
      CerrarSesion: function () {
        this.$store.dispatch('DesAsignarUsuario');
        this.$store.dispatch('DesAsignarUsuarioAux');
        this.$router.push('/');
      },
      CerrarSesionAuxiliar: function () {
        this.$store.dispatch('DesAsignarUsuarioAux');
        this.$router.push('/');
      },
      configurarForm(){
        var peremp = this.per.PerEmp.find(e => e.EmpId === this.empId)
        this.empresa = peremp;

        this.tituloPrincipal = this.per.PerNom;
        this.tituloSecundario = "";
      }
    },
    mounted(){
      this.configurarForm();
      if (this.$store.state.esTienda || this.$store.state.esDepartamento) {
        this.perCatIdAuxFiltro = 101;
        this.perCatAuxTitulo = "Empleado";
      }
      else {
        this.perCatIdAuxFiltro = 102;
        this.perCatAuxTitulo = "Tienda";
      }
    }
  }
</script>